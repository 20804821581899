import * as React from 'react';
import Button from '@mui/material/Button';


class Resume extends React.Component {

    render() {
        return (
            <div>
                <header className="App-header">
                    <h1>Resume</h1>
                    <Button variant="contained">Do Nothing Button</Button>
                </header>
            </div>
        )
    }
}

export default Resume;