import * as React from 'react';

class Resume extends React.Component {

    render() {
        return (
            <div>
                <header className="App-header">
                    <h1>404</h1>
                    <p>There is not a page here.  Use the Navbar above to route back to a listed page.</p>
                </header>
            </div>
        )
    }
}

export default Resume;