import React, { useEffect } from "react";

export const Avatar = () => {

    const [ref, setDivRef] = React.useState(null);

    useEffect(() => {
        if (!ref) return;

        let frame = 0; // requestAnimationFrame will not return a 0, so it's a valid uninitialized state.
        let angle = 0;
        let velocity = 0;

        ref.onclick = () => velocity += 2;
        ref.ondragstart = () => false;

        let lastNow = performance.now();

        function animate(now) {
            let delta = now - lastNow;
            lastNow = now;

            velocity *= Math.exp(-0.001 * delta);
            angle = (angle + (velocity * delta)) % 360;
            ref.style.transform = `rotate(${angle}deg)`;
            frame = requestAnimationFrame(animate);
        }

        frame = requestAnimationFrame(animate);

        return () => {
            if (frame) cancelAnimationFrame(frame);
        }

    }, [ref]);
    
    return (
        <div ref={setDivRef} className="w-40 h-40 relative mx-auto mb-4 cursor-pointer">
            <img className="rounded-full" fill src="/profile.png" alt="avatar" />
        </div>
    );
}

export default Avatar;